import { useToast } from '~/modules/ui/components/toast'

// Log out function
export const useLogout = async () => {
  const supabase = useSupabaseClient()
  const user = useSupabaseUser()
  const userProfileStore = useUserProfileStore()

  useLogger().debug('Signing out', '#useAuth/useLogout')

  if (!user) {
    return useToast().toast({ title: 'You are not logged in', variant: 'error' })
  }

  const { id: signingOutLoaderToastId } = useToast().toast({ title: 'Signing out...', variant: 'loading' })

  // Capture event indicating user intentionally signed out
  useEventCapture('user_signed_out')

  // Clear user profile data from Pinia store and unsubscribe from profile changes
  userProfileStore.clearProfile()
  userProfileStore.unsubscribeFromProfileChanges()

  // Sign out the user from supabase auth
  await supabase.auth.signOut({ })

  // Dismiss loader
  useToast().dismiss(signingOutLoaderToastId)

  // Show success toast
  useToast().toast({ title: 'Signed out', variant: 'success' })

  // Redirect to home page
  return navigateTo('/')
}

// Get user's email
export const useAuthUserEmail = () => {
  const user = useSupabaseUser()
  return user?.value?.email ?? null
}

// Get user's name
export const useAuthUserName = () => {
  const user = useSupabaseUser()
  return user?.value?.user_metadata?.name ?? null
}

/**
 * Parse the user's name from authentication metadata.
 *
 * This function attempts to extract and parse the user's full name from
 * Supabase authentication metadata. If successful, it returns the parsed name.
 *
 * @returns {object | null} An object containing parsed name parts, or null if parsing fails.
 */
export const useParseUserNameFromAuth = () => {
  const user = useSupabaseUser()

  if (!user.value) { return null }

  // Get the user's full name from auth metadata
  // Prefer 'full_name' if available, fallback to 'name', or use null if neither exists
  const fullName = user?.value?.user_metadata?.full_name || user?.value?.user_metadata?.name || null

  // If we have a full name, parse it into parts
  if (fullName) {
    return getParsedName(fullName)
  }
  else {
    // Log an error if we couldn't find a valid name to parse
    useLogger().error(`Unable to parse human name from user `)
    return null
  }
}
